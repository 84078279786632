/* DM Mono - 400 */
@font-face {
    font-family: 'DM Mono';
    src: url('./DMMono-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  /* Manrope - 400 */
  @font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  /* Manrope - 500 */
  @font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  /* Manrope - 700 */
  @font-face {
    font-family: 'Manrope';
    src: url('./Manrope-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  /* Poppins - 700 */
  @font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }