@font-face {
  font-family: DM Mono;
  src: url("DMMono-Regular.2f34c6cd.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Regular.f16668b1.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Medium.5a13b866.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Manrope;
  src: url("Manrope-Bold.3eb0b65b.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Bold.9cf8bd9d.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=index.efad0d1b.css.map */
